@font-face {
  font-family: 'CarlaSansRegular';
  src: local('CarlaSansRegular'),
       url('./CarlaSansRegular.woff') format('woff'),
       url('./CarlaSansRegular.woff2') format('woff2');
}

@font-face {
  font-family: 'CarlaSansBold';
  src: local('CarlaSansBold'),
       url('./CarlaSansBold.woff') format('woff'),
       url('./CarlaSansBold.woff2') format('woff2');
}